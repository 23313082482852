body{
    font-family: 'agenda', sans-serif;
    padding: 0;
    margin: 0;
}

h1, h2, h3, p{margin-bottom: 0;}
.container {
    max-width: 1170px;
}

.mac-top-section {
    max-width: 1170px;
    margin: 0 auto;
    text-align: left;
       padding: 50px 15px 40px;
}

.mac-top-section h1 {
    font-weight: 700;
    font-size: 49px;
    color: #01073A;
    line-height: 58.8px;
}
.mac-top-section img.mac-separator-icon {
    margin: 15px 0px;
}
.mac-top-section p {
    font-size: 16px;
    color: #000000;
    line-height: 20px;
}

.mac-top-section svg {
    margin: 15px 0;
}
.mac-about-your-outer {
    background: #fff;
    padding: 76px 55px;
    height: 100%;
    max-width: 549px;
    border: 1px solid #01073A;
}

.mac-inner-content {
    padding-bottom: 40px;
}
.mac-about-your {
    display: flex;
    align-items: center;
    padding-bottom: 40px;
}
.mac-about-your h2 {
    font-weight: 700;
    font-size: 32px;
    color: #01073A;
    line-height: 42px;
}
.mac-abt-ur-head {
    margin-right: 20px;
}
.mac-form-radio-btn {
    padding: 20px 0 55px;
}

.mac-mortagage-form-section h3 {
    font-size: 20px;
    color: #01073A;
    margin-bottom: 14px;
}
.mac-radio input {
       position: absolute;
       opacity: 0;
       cursor: pointer;
   }

   .checkround {
       position: absolute;
       top: 0;
        left: auto;
        height: 42px;
        width: 42px;
        background-color: #fff;
        border: 2px solid #12579F;
        border-radius: 50%;
        right: 0;
    }

   .mac-radio input:checked ~ .checkround {
       background-color: #fff;
   }
   .checkround:after {
       content: "";
       position: absolute;
       display: none;
   }

   .mac-radio input:checked ~ .checkround:after {
      display: block;
   }

   .mac-radio .checkround:after {
        left: 3px;
        top: 3px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: linear-gradient(0deg, #921D80 0%, #9F4EB0 100%);
   }
.mac-form-radio-btn label {
    font-size: 16px;
    color: #000000;
    position: relative;
    cursor: pointer;
    padding: 10px 55px 0 0;
    margin-right: 35px;
}
.mac-frm-input {
    max-width: 428px;
}
.mac-frm-input label {
    font-size: 20px;
    color: #01073A;
    display: block;
    line-height: 25px;
        margin: 0px 0px 15px;
}
.mac-frm-input input {
    border: 1px solid #12579F;
    box-sizing: border-box;
    border-radius: 5px;
    width: 100%;
    height: 33px;
    padding: 0 10px;
    font-size: 16px;
    color: #000;
}
.mac-frm-input input:focus {
    outline: none;
}
.mac-frm-input span {
    position: relative;
    display: block;
}

.mac-frm-input .mac-info-icon {
    position: absolute;
    right: 6px;
    top: 1px;
}
.mac-input-box:not(:last-child) {
    padding-bottom: 55px;
}
body .__react_component_tooltip {
background: #01073A;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    max-width: 200px;
    padding: 13px 20px;
        opacity: 1 !important;
}

body .__react_component_tooltip.place-top::after, body .__react_component_tooltip:after{

    display:none;
}

.mac-calc-main {
    background: #01073A;
    padding: 60px 40px 30px;
    width: 100%;
    text-align: center;
    height: 100%;
    float: right;
}

.mac-calc-main h2.box-heading {
    color: #fff;
    font-size: 36px;
    font-weight: 700;
    line-height: 45px;
    margin: 0px 0px 30px;
}

.mac-income-wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    max-width: 510px;
    margin: auto;
}

.mac-income-wrapper .mac-yearbox {
    -ms-flex: 0 0 33.33%;
    flex: 0 0 33.33%;
    max-width: 33.33%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.mac-yearbox h3 {
    font-weight: 900;
    font-size: 28px;
    line-height: 35px;
    text-align: center;
    color: #fff;
    margin: 0px 0px 20px;
}

.mac-small-text .mac-yearbox h3 {
  font-size: 18px;
}
.mac-yearbox h3 span {
   /* text-overflow: ellipsis;
    word-break: keep-all;
    white-space: nowrap;*/
    display: block;
    overflow: hidden;
        word-break: break-all;
}
.mac-bar-content-wrapper {
    align-self: flex-end;
    min-height: 230px;
}
.mac-year-content {
    padding: 25px 10px 0px;
}
.mac-grey-box .mac-year-content {
    padding-left: 20px;
    padding-right: 20px;
}
.mac-calc-main .mac-yearbox p {
    color: #fff;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
}

.mac-year-bar {
    background-color: #C4C4C4;
    height: 7px;
    width: 100%;
    position: relative;
}

.mac-purpl-box .mac-year-bar {
    background-color: #AE1D57;
}

.mac-blue-box .mac-year-bar {
    background-color: #6593F5;
}

.mac-purpl-box .mac-year-bar:before {content: "";height: 20px;width: 20px;background-color: #AE1D57;border-radius: 50%;position: absolute;left: 0px;top: 50%;transform: translateY(-50%);}

.mac-blue-box .mac-year-bar:before {content: "";height: 20px;width: 20px;background-color: #6593F5;border-radius: 50%;position: absolute;left: auto;top: 50%;transform: translateY(-50%);right: 0px;}

.mac-calc-main p {
    font-weight: normal;
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
    color: #fff;
}

.mac-calc-footer a.btn {
    background-color: #AE1D57;
    border-radius: 60px;
    border: 0px;
    color: #fff;
    font-weight: bold;
    font-size: 28px;
    line-height: 35px;
    text-align: center;
    text-transform: capitalize;
    padding: 14px 27px;
    transition: all 0.4s ease-in-out;
    margin-bottom: 20px;

     -webkit-transition: background 0.4s ease-in-out;
  -moz-transition: background 0.4s ease-in-out;
  -o-transition: background 0.4s ease-in-out;
  transition: background 0.4s ease-in-out;
}
.mac-calc-footer a.btn:hover{
background: #6593F5;
background-color: #6593F5;
}

.mac-calc-footer {
    padding: 25px 30px 0px;
}





@media(max-width:1199px){
    .mac-abt-ur-head {
        margin-right: 15px;
    }

    .mac-abt-ur-head svg {
        width: 85px;
    }

    .mac-about-your-outer {
        padding: 76px 55px;
    }

    .mac-about-your h2 {
        font-size: 29px;
    }

    .mac-about-your {
        padding-bottom: 20px;
    }
    .mac-form-radio-btn label {
        padding: 2px 45px 0 0;
        margin-right: 25px;
    }
    .checkround {
        height: 32px;
        width: 32px;
    }
    .mac-radio .checkround:after {
        width: 22px;
        height: 22px;
    }
    .mac-form-radio-btn {
        padding: 15px 0 45px;
    }
    .mac-bar-content-wrapper {
    min-height: 272px;
}
.mac-small-text .mac-yearbox h3 {
    font-size: 17px;
}
}

@media (max-width: 991px)
{
    .mac-income-wrapper .mac-yearbox {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    position: relative;
    padding-left: 53px;
        padding-bottom: 22px;
    padding-top: 22px;
}
.mac-income-wrapper .mac-yearbox:first-child{
        padding-top: 0px;
}
.mac-income-wrapper .mac-yearbox:Last-child{
        padding-bottom: 0px;
}
.mac-year-bar {
    position: absolute;
    width: 7px;
    height: 100%;
    left: 10px;
    top: 0px;
}
.mac-purpl-box .mac-year-bar:before {
    left: -6px;
    top: -1px;
    transform: translateY(0%);
}
.mac-blue-box .mac-year-bar:before {
    left: -6px;
    top: auto;
    transform: translateY(0%);
    right: auto;
    bottom: -1px;
}
.mac-yearbox h3 {
    text-align: left;
        margin: 0px 0px 12px;
}
.mac-year-content {
    padding: 0px 0px 0px;
}
.mac-grey-box .mac-year-content {
    padding-left: 0px;
    padding-right: 0px;
}
.mac-calc-main .mac-yearbox p {
    text-align: left;
    margin: 0px;
}
.mac-calc-footer {
    padding: 60px 0px 0px;
}
.mac-bar-content-wrapper {
    min-height: inherit;
}
.mac-income-wrapper .mac-yearbox {
    justify-content: flex-start;
    }
    .mac-small-text .mac-yearbox h3 {
    font-size: 20px;
}
}

btn:focus {outline:0;}

@media(max-width:767px){
.mac-calc-footer a.btn {
    font-size: 16px;
    padding: 6px 32px;
    }
.mac-calc-main {
    margin-top: 40px;
    margin-bottom: 40px;
    height: auto;
}
.mac-about-your-outer {
    max-width: 100%;
}
.mac-frm-input {
    max-width: 100%;
}
.mac-inner-content {
    padding-bottom: 0px;
}
}

@media(max-width:575px){
.mac-abt-ur-head {
    max-width: 52px;
}
.mac-abt-ur-head img{
    max-width:100%;
}
}




@media(max-width:479px){
    .mac-top-section h1 {
          font-size: 22px;
        line-height: 27px;
    }
    .mac-top-section {
         padding: 40px 15px 45px;
        text-align: center;
    }
    .mac-about-your h2 {
       font-size: 19px;
    }
    .mac-about-your-outer {
        padding: 25px 15px 35px;
    }
    .mac-abt-ur-head svg {
        width: 75px;
    }
    .mac-abt-ur-head {
        margin-right: 15px;
    }
    .mac-about-your {
        flex-wrap: wrap;
    }
    .mac-form-radio-btn {
           padding: 0px 0 40px;
    }
    .mac-input-box:not(:last-child) {
        padding-bottom: 35px;
    }
    .mac-calc-main h2.box-heading {
    font-size: 22px;
    line-height: 28px;
}
body .__react_component_tooltip {
    right: 0px;
    left: auto !important;
    top: -98px !important;
    position: absolute !important;
    max-width: 217px;
}

.mac-input-box {
    position: relative;
}
}
